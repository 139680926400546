import { MinusCircleOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { Fragment, useState } from "react";
import Select from "react-select";


export const TestFieldsRow = ({ keyVal, name, getTestsList, remove, ...restField }) => {
  const [priceValue, setPriceValue] = useState('');
  const [sampleRequiredValue, setSampleRequiredValue] = useState('');
  return <Fragment key={keyVal}>
    <div className="col-12 col-md-4 col-xl-4">
      <div className="form-group local-forms">
        <label>
          Test
        </label>
        <Form.Item
          {...restField}
          name={[name, 'id']}
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
          getValueFromEvent={val => {
            setPriceValue(val?.price || '');
            setSampleRequiredValue(val?.sample_required || '');
            return val;
          }}
        >
          <Select
            options={getTestsList?.map(z => {
              return {
                label: z?.name,
                value: z?.id,
                price: z?.price,
                sample_required: z?.sample_required,
              }
            })}
            components={{
              IndicatorSeparator: () => null
            }}
            menuPortalTarget={document.body}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                '&:hover': {
                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                },
                borderRadius: '10px',
                fontSize: "14px",
                minHeight: "45px",
              }),
              dropdownIndicator: (base, state) => ({
                ...base,
                transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                transition: '250ms',
                width: '35px',
                height: '35px',
              }),
            }}
          />
        </Form.Item>
      </div>
    </div>
    <div className="col-12 col-md-4 col-xl-4">
      <div className="form-group local-forms">
        <label>
          Sample required
        </label>
        <input
          className="form-control"
          type="text"
          placeholder=""
          value={sampleRequiredValue}
          readOnly
        />
      </div>
    </div>

    <div className="col-5 col-md-2 col-xl-2">
      <div className="form-group local-forms">
        <label>
          price
        </label>
        <input
          className="form-control"
          type="text"
          placeholder=""
          value={priceValue}
          readOnly
        />
      </div>
    </div>
    <div className="col-5 col-md-1 col-xl-1">
      <div className="form-group local-forms">
        <Form.Item
          {...restField}
          name={[name, 'test_status']}
          valuePropName="checked"
        >
          <label className="custom_check" style={{ marginTop: '22px' }}>
            <input type="checkbox" />
            <span className="checkmark" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Urgent
          </label>
        </Form.Item>
      </div>
    </div>
    <div className="col-1 col-md-1 col-xl-1">
      {keyVal !== 0 && <MinusCircleOutlined onClick={() => remove(name)} style={{ marginTop: '14px', marginLeft: '10px' }} />}
    </div>
  </Fragment>
}