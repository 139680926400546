import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import {
  cameraicon,
  doctor,
  imagesend,
  menuicon16,
  pdficon,
  profilebg,
  profileuser01
} from "../imagepath";
import { Button, DatePicker, Divider, Form, notification, Radio, Spin, Upload } from "antd";
import FeatherIcon from "feather-icons-react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store/root-store";
import moment from "moment";
import { LoadingOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { SubmitButton } from "../../common-components/submitButton";
import { CheckOutlined } from "@mui/icons-material";
import dayjs from "dayjs";
import PhoneInput from 'react-phone-number-input';
import { CommonSelect } from "../../common-components/commonSelect";
import { DocumentHead } from "../../utils/documentHead";
import { PageLayout } from "../../common-components/pageLayout";
import { generatePatientReceipt } from "../../utils/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { HOME_PAGE_URL } from "../../api/constants";


const PatientDetails = observer(() => {
  const {
    getUserData,
    patientsStore: {
      patientRecordLoading,
      creatingRecord,
      deletePatient,
      updatingRecord,
      getPatientsData,
      patientsListLoading,
      getPatientRecord,
      updatePatientRecord,
      fetchPatientRecord,
      fetchOldPatientRecord,
      addOldPatientRecord,
      resetPatientRecord,
    },
    testsStore: {
      getTestsList,
      fetchTestsList,
      createPatientTest,
      deletePatientTest,
      updateTestDetail
    },
    panelsStore: {
      fetchPanelsList,
      getPanelsList
    },
    doctorsStore: {
      getDoctorsList,
      fetchDoctorsList
    }
  } = useStore('');
  const [searchParams] = useSearchParams();
  const isOldRecord = searchParams.get("is_old_record");
  const [date_of_birth_state, setDate_of_birth_state] = useState(null);
  const [imageUrl, setImageUrl] = useState();
  const [testRowLoading, setTestRowLoading] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    if (isOldRecord) {
      fetchOldPatientRecord(id);
    } else {
      fetchPatientRecord(id);
    }
    fetchDoctorsList();
    fetchTestsList();
    fetchPanelsList();
  }, [id]);

  const updateDataInFields = (updatedData) => {
    if (updatedData?.profile_image) {
      setImageUrl(updatedData?.profile_image);
    }
    if (updatedData?.date_of_birth) {
      setDate_of_birth_state(dayjs(updatedData.date_of_birth));
    }
    const findPanel = getPanelsList?.find(z => z?.id === updatedData?.panel);
    const findReferred = getDoctorsList?.find(z => z?.id === updatedData?.referred_by);
    const discountAmount = Number(updatedData?.test_detail?.discount || 0);
    const payableAmount = Number(updatedData?.test_detail?.total_amount || 0) - Number(discountAmount);
    const dueAmount = Number(payableAmount) - Number(updatedData?.test_detail?.paid_amount || 0);
    form.setFieldsValue({
      title: { label: updatedData?.title, value: updatedData?.title },
      profile_image: updatedData?.profile_image,
      first_name: updatedData?.first_name,
      last_name: updatedData?.last_name,
      age: updatedData?.age,
      contact_number: updatedData?.contact_number,
      date_of_birth: updatedData?.date_of_birth,
      email: updatedData?.email,
      gender: { label: updatedData?.gender, value: updatedData?.gender },
      blood_group: { label: updatedData?.blood_group, value: updatedData?.blood_group },
      referred_by: { label: findReferred?.name, value: findReferred?.id },
      nic: updatedData?.nic,
      passport_number: updatedData?.passport_number,
      total_amount: updatedData?.test_detail?.total_amount,
      discount_amount: updatedData?.test_detail?.discount,
      paid_amount: updatedData?.test_detail?.paid_amount,
      payable_amount: payableAmount,
      due_amount: dueAmount,
      panel: { label: findPanel?.name, value: findPanel?.id },
      tests: updatedData?.patient_tests?.length ? updatedData?.patient_tests?.map(z => {
        const dataObj = (z?.tests?.source === 'platform' && z?.tests?.updated_test?.name) ? z?.tests?.updated_test : z?.tests;
        return {
          patient_test_id: z?.patient_test_id,
          result: z?.tests?.result?.current_result,
          id: {
            label: dataObj?.name,
            value: z?.tests?.id,
            price: dataObj?.price,
            sample_required: dataObj?.sample_required,
          },
          test_status: z?.status === 'Urgent',
          price: dataObj?.price,
          sample_required: dataObj?.sample_required,
        }
      }) : {}
    })
  }
  useEffect(() => {
    if (getPatientRecord) {
      updateDataInFields(getPatientRecord)
    }
  }, [getPatientRecord]);

  useEffect(() => {
    return () => {
      resetPatientRecord();
    }
  }, []);


  const getBase64 = async (img) => {
    const reader = new FileReader();
    await reader.addEventListener('load', () => {
      form.setFieldsValue({
        profile_image: reader.result,
      });
      setImageUrl(reader.result);
    });
    reader.readAsDataURL(img);
    return reader.result;
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      {<PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const handleAddNewTest = async (key) => {
    setTestRowLoading(key);
    const payloadObj = {
      "status": form.getFieldValue('tests')[key]?.test_status ? 'Urgent' : 'Normal',
      "patient_detail": getPatientRecord?.test_detail?.test_detail_id,
      "test": form.getFieldValue('tests')[key]?.id?.value,
    }
    const createResponse = await createPatientTest(payloadObj);
    if (createResponse?.status_code === 200) {
      const totalPrice = form.getFieldValue('tests')?.filter(z => z?.id)?.reduce((a, b) => a + Number(b?.id?.price), 0);
      const payloadTestDetail = {
        "discount": Number(form.getFieldValue('discount_amount')),
        "total_amount": totalPrice,
        "paid_amount": Number(form.getFieldValue('paid_amount'))
      }
      await updateTestDetail(getPatientRecord?.test_detail?.test_detail_id, payloadTestDetail);
      let res;
      if (isOldRecord) {
        res = await fetchOldPatientRecord(id);
      } else {
        res = await fetchPatientRecord(id);
      }
      if (res?.data) {
        updateDataInFields(res?.data);
      }
    } else {
      notification.error({ message: JSON.stringify(createResponse?.message || 'Error Occured!') })
    }
    setTestRowLoading(null);
  };

  const handleDeleteTest = async (key) => {
    setTestRowLoading(key);
    if (form.getFieldValue('tests')[key]?.result) {
      notification.warning({ message: 'Result already entered for this test.' })
    } else {
      await deletePatientTest(form.getFieldValue('tests')[key]?.patient_test_id);
      const totalPrice = form.getFieldValue('tests')?.filter(z => z?.id?.value !== form.getFieldValue('tests')[key]?.id?.value)?.reduce((a, b) => a + Number(b?.id?.price), 0);
      const payloadTestDetail = {
        "discount": Number(form.getFieldValue('discount_amount')),
        "total_amount": totalPrice,
        "paid_amount": Number(form.getFieldValue('paid_amount'))
      }
      await updateTestDetail(getPatientRecord?.test_detail?.test_detail_id, payloadTestDetail);
      let res;
      if (isOldRecord) {
        res = await fetchOldPatientRecord(id);
      } else {
        res = await fetchPatientRecord(id);
      }
      if (res?.data) {
        updateDataInFields(res?.data);
      }
    }
    setTestRowLoading(null);
  }

  const handleAddOldPatientRecord = async (values) => {
    const discountAmountType = values?.discount_amount_type?.value;
    const discountAmount = discountAmountType === 'percent' ? values?.total_amount / 100 * values?.discount_amount : values?.discount_amount;
    const dataObj = {
      patient_detail: {
        age: values?.age,
        blood_group: values?.blood_group?.value,
        contact_number: values?.contact_number,
        date_of_birth: values?.date_of_birth,
        email: values?.email,
        first_name: values?.first_name,
        gender: values?.gender?.value,
        guardian: values?.guardian,
        last_name: values?.last_name,
        nic: values?.nic,
        passport_number: values?.passport_number,
        profile_image: values?.profile_image,
        referred_by: values?.referred_by?.value,
        title: values?.title?.value,
        panel: values.panel.value,
        mr_no: isOldRecord,
      },
      tests: values?.tests?.map(z => {
        return {
          id: z?.id?.value,
          test_status: z?.test_status ? 'Urgent' : 'Normal',
          sample_required: values?.sample_required?.value,
        }
      }),
      "test_invoice": {
        "discount_amount": Number(discountAmount || 0),
        "total_amount": values?.total_amount,
        "paid": Number(values?.paid_amount || 0),
        "due_amount": Number(values?.due_amount || 0),
      }
    }
    const res = await addOldPatientRecord(dataObj);
    if (res?.status_code === 200) {
      const totalPrice = form.getFieldValue('tests')?.filter(z => z?.id)?.reduce((a, b) => a + Number(b?.id?.price), 0);
      const payloadTestDetail = {
        "discount": Number(form.getFieldValue('discount_amount')),
        "total_amount": totalPrice,
        "paid_amount": Number(form.getFieldValue('paid_amount'))
      }
      updateTestDetail(getPatientRecord?.test_detail?.test_detail_id, payloadTestDetail);
      notification.success({ message: res?.message });
      form.resetFields();
      navigate(`${HOME_PAGE_URL}`)
    } else {
      notification.error({ message: 'Error occured!', description: JSON.stringify(res?.message) })
    }
  }
  const handleUpdatePatientRecord = async (values) => {
    const dataObj = {
      age: values?.age,
      blood_group: values?.blood_group?.value,
      contact_number: values?.contact_number,
      date_of_birth: values?.date_of_birth,
      email: values?.email,
      first_name: values?.first_name,
      gender: values?.gender?.value,
      guardian: values?.guardian,
      last_name: values?.last_name,
      nic: values?.nic,
      passport_number: values?.passport_number,
      profile_image: values?.profile_image,
      referred_by: values?.referred_by?.value,
      title: values?.title?.value,
      panel: values.panel.value,
    }
    const res = await updatePatientRecord(id, dataObj);
    if (res?.status_code === 200) {
      const totalPrice = form.getFieldValue('tests')?.filter(z => z?.id)?.reduce((a, b) => a + Number(b?.id?.price), 0);
      const payloadTestDetail = {
        "discount": Number(form.getFieldValue('discount_amount')),
        "total_amount": totalPrice,
        "paid_amount": Number(form.getFieldValue('paid_amount'))
      }
      updateTestDetail(getPatientRecord?.test_detail?.test_detail_id, payloadTestDetail);
      notification.success({ message: res?.message });
      form.resetFields();
      navigate(`${HOME_PAGE_URL}`)
    } else {
      notification.error({ message: 'Error occured!', description: JSON.stringify(res?.message) })
    }
  }

  return (
    <Spin spinning={patientRecordLoading}>
      <>
        <DocumentHead title={'Patients'} />
        <Header />
        <Sidebar id="menu-item2" id1="menu-items2" activeClassName="patient" />
        <>
          <PageLayout>
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Patients </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Patient Profile
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="about-info">
                          <h4>
                            {/* Patients Profile */}
                            {/* <span>
                                <Link to="#">
                                  <i className="feather-more-vertical">
                                    <FeatherIcon icon="more-vertical" />
                                  </i>
                                </Link>
                              </span> */}
                          </h4>
                        </div>
                        <div className="doctor-profile-head">
                          {/* <div className="profile-bg-img">
                            <img src={imageUrl || profilebg} alt="Profile" style={{ background: '#ccc', objectFit: 'none', maxHeight: '250px' }} />
                          </div> */}
                          <div className="row">
                            <div className="col-lg-4 col-md-4">
                              <div className="profile-user-box">
                                <div className="profile-user-img" style={{marginRight: '35px', top: '-20px'}}>
                                  <img src={imageUrl || profileuser01} alt="Profile" style={{ background: '#ccc', width: '141px', height: '143px' }} />
                                  {/* <div className="form-group doctor-up-files profile-edit-icon mb-0">
                                      <div className="uplod d-flex">
                                        <label className="file-upload profile-upbtn mb-0">
                                          <img src={cameraicon} alt="Profile" />
                                          <input type="file" />
                                        </label>
                                      </div>
                                    </div> */}
                                </div>
                                <div className="names-profiles">
                                  <h4>{getPatientRecord?.title} {getPatientRecord?.first_name} {getPatientRecord?.last_name}</h4>
                                  <h5>{getPatientRecord?.age} years old</h5>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8 col-md-8 row">
                              <div className="col-xxl-5 d-flex align-items-center">
                                <div className="follow-group">
                                  <div className="doctor-follows">
                                    <h5>Patient ID</h5>
                                    <h4>{getPatientRecord?.patient_id || '-'}</h4>
                                  </div>
                                  <div className="doctor-follows">
                                    <h5>MR number</h5>
                                    <h4>{getPatientRecord?.mr_no || '-'}</h4>
                                  </div>
                                  <div className="doctor-follows">
                                    <h5>Total Tests</h5>
                                    <h4>{getPatientRecord?.patient_tests?.length || '-'}</h4>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xxl-7 d-flex align-items-center">
                                <div className="follow-btn-group" style={{display: 'flex', flexWrap: 'wrap', gap: '15px'}}>
                                  <button
                                    className="btn btn-info follow-btns" style={{width: 'fit-content', marginRight: 0}}
                                    onClick={async () => {
                                      generatePatientReceipt(getPatientRecord, getUserData);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faFilePdf} />
                                  </button>
                                  <Link to={`/test-results?patient_id=${getPatientRecord?.patient_id}`}
                                    className="btn btn-info follow-btns" style={{width: 'fit-content', marginRight: 0}}
                                  >
                                    Add Results
                                  </Link>
                                  <Link to={`/edit-patients/${getPatientRecord?.patient_id}`}
                                    className="btn btn-info follow-btns" style={{width: 'fit-content', marginRight: 0}}
                                  >
                                    Edit
                                  </Link>
                                  <button
                                    className="btn btn-info message-btns" style={{width: 'fit-content'}}
                                    data-bs-toggle="modal" data-bs-target="#delete_patient"
                                  >
                                    Delete
                                  </button>
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-lg-4">
                      <div className="doctor-personals-grp">
                        <div className="card">
                          <div className="card-body">
                            <div className="heading-detail ">
                              <h4 className="mb-3">About me</h4>
                              <p>
                                Hello I am {getPatientRecord?.first_name} {getPatientRecord?.last_name} a Gynaecologist in
                                Sanjivni Hospital Surat. I love to work with all
                                my hospital staff and seniour doctors.
                              </p>
                            </div>
                            <div className="about-me-list">
                              <ul className="list-space">
                                <li>
                                  <h4>Gender</h4>
                                  <span>{getPatientRecord?.gender}</span>
                                </li>
                                <li>
                                  <h4>Operation Done</h4>
                                  <span>0</span>
                                </li>
                                <li>
                                  <h4>Age</h4>
                                  <span>{getPatientRecord?.age}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="doctor-personals-grp">
                        <div className="card">
                          <div className="card-body">
                            <div className="heading-detail">
                              <h4>Skills:</h4>
                            </div>
                            <div className="skill-blk">
                              <div className="skill-statistics">
                                <div className="skills-head">
                                  <h5>Heart Beat</h5>
                                  <p>45%</p>
                                </div>
                                <div className="progress mb-0">
                                  <div
                                    className="progress-bar bg-operations"
                                    role="progressbar"
                                    style={{ width: "45%" }}
                                    aria-valuenow={45}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </div>
                              <div className="skill-statistics">
                                <div className="skills-head">
                                  <h5>Haemoglobin</h5>
                                  <p>85%</p>
                                </div>
                                <div className="progress mb-0">
                                  <div
                                    className="progress-bar bg-haemoglobin"
                                    role="progressbar"
                                    style={{ width: "85%" }}
                                    aria-valuenow={85}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </div>
                              <div className="skill-statistics">
                                <div className="skills-head">
                                  <h5>Blood Pressure </h5>
                                  <p>65%</p>
                                </div>
                                <div className="progress mb-0">
                                  <div
                                    className="progress-bar bg-statistics"
                                    role="progressbar"
                                    style={{ width: "65%" }}
                                    aria-valuenow={65}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </div>
                              <div className="skill-statistics">
                                <div className="skills-head">
                                  <h5>Sugar </h5>
                                  <p>90%</p>
                                </div>
                                <div className="progress mb-0">
                                  <div
                                    className="progress-bar bg-visit"
                                    role="progressbar"
                                    style={{ width: "90%" }}
                                    aria-valuenow={90}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  <div className="col-lg-12">
                    <div className="doctor-personals-grp">
                      <div className="card">
                        <div className="card-body">
                          <div className="tab-content-set">
                            <ul className="nav">
                              <li>
                                <Link className={activeTab === 1 ? 'active' : ''} onClick={() => setActiveTab(1)}>
                                  <span className="set-about-icon me-2">
                                    <img src={doctor} alt="" />
                                  </span>
                                  Patient detail
                                </Link>
                              </li>
                              <li>
                                <Link className={activeTab === 2 ? 'active' : ''} onClick={() => setActiveTab(2)}>
                                  <span className="set-about-icon me-2">
                                    <img src={menuicon16} alt="" />
                                  </span>
                                  Settings
                                </Link>
                              </li>
                            </ul>
                          </div>
                          {activeTab === 1 ? <>
                            <div className="personal-list-out">
                              <div className="row gy-4">
                                <div className="col-xl-3 col-md-6">
                                  <div className="detail-personal">
                                    <h2>Full Name</h2>
                                    <h3>{getPatientRecord?.title} {getPatientRecord?.first_name} {getPatientRecord?.last_name}</h3>
                                  </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                  <div className="detail-personal">
                                    <h2>Mobile </h2>
                                    <h3>{getPatientRecord?.contact_number || '-'}</h3>
                                  </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                  <div className="detail-personal">
                                    <h2>Blood group</h2>
                                    <h3>{getPatientRecord?.blood_group || '-'}</h3>
                                  </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                  <div className="detail-personal">
                                    <h2>Date of birth</h2>
                                    <h3>{getPatientRecord?.date_of_birth || '-'}</h3>
                                  </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                  <div className="detail-personal">
                                    <h2>Gender</h2>
                                    <h3>{getPatientRecord?.gender || '-'}</h3>
                                  </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                  <div className="detail-personal">
                                    <h2>Age </h2>
                                    <h3>{getPatientRecord?.age || '-'}</h3>
                                  </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                  <div className="detail-personal">
                                    <h2>Email</h2>
                                    <h3>{getPatientRecord?.email || '-'}</h3>
                                  </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                  <div className="detail-personal">
                                    <h2>Registered at</h2>
                                    <h3>{getPatientRecord?.registered_at || '-'}</h3>
                                  </div>
                                </div>
                              </div>

                            </div>
                            {/* <div className="hello-park">
                              <p>
                                Completed my graduation in Gynaecologist
                                Medicine from the well known and renowned
                                institution of India – SARDAR PATEL MEDICAL
                                COLLEGE, BARODA in 2000-01, which was affiliated
                                to M.S. University. I ranker in University exams
                                from the same university from 1996-01.
                              </p>
                              <p>
                                Worked as Professor and Head of the department ;
                                Community medicine Department at Sterline
                                Hospital, Rajkot, Gujarat from 2003-2015
                              </p>
                            </div> */}
                          </> : <>
                            <div className="setting-form-blk">
                              <Form form={form} initialValues={{
                                tests: [{}]
                              }} onValuesChange={(val, allValues) => {
                                const totalPrice = allValues?.tests?.filter(z => z?.id)?.reduce((a, b) => a + Number(b?.id?.price), 0);
                                const discountAmountType = allValues?.discount_amount_type?.value;
                                const discountAmount = discountAmountType === 'percent' ? Number(totalPrice / 100 * Number(allValues?.discount_amount || 0)) : Number(allValues?.discount_amount || 0);
                                const payableAmount = Number(totalPrice) - Number(discountAmount);
                                const dueAmount = Number(payableAmount) - Number(allValues?.paid_amount || 0);
                                const tests = allValues?.tests?.map(item => {
                                  return {
                                    ...item,
                                    sample_required: item?.id?.sample_required || '',
                                    price: item?.id?.price,
                                  }
                                });
                                form.setFieldsValue({
                                  total_amount: totalPrice,
                                  payable_amount: payableAmount,
                                  due_amount: dueAmount,
                                  tests: tests,
                                });
                              }} onFinish={values => {
                                if (isOldRecord) {
                                  handleAddOldPatientRecord(values);
                                } else {
                                  handleUpdatePatientRecord(values);
                                }
                              }}>
                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-heading">
                                      <h4>Patients Details</h4>
                                    </div>
                                  </div>
                                  <div className="createPatientFormWrapper col-12" style={{ flexDirection: 'column' }}>
                                    <div className="profileImageDiv">
                                      <Form.Item name={'profile_image'} valuePropName="file" style={{ padding: '28px 20px 20px 28px', border: '2px solid rgba(46, 55, 164, 0.1)', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }} getValueFromEvent={(val) => {
                                        return getBase64(val.file.originFileObj);
                                      }}>
                                        <Upload
                                          name="avatar"
                                          listType="picture-card"
                                          className="avatar-uploader"
                                          showUploadList={false}
                                          customRequest={() => { }}
                                        >
                                          {imageUrl ? (
                                            <img
                                              src={imageUrl}
                                              alt="avatar"
                                              style={{
                                                height: '100%',
                                              }}
                                            />
                                          ) : (
                                            uploadButton
                                          )}
                                        </Upload>
                                      </Form.Item>
                                    </div>

                                    <div className="fieldsWrapperDiv row">
                                      <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                          <label>
                                            Title
                                          </label>
                                          <Form.Item name={'title'}>
                                            <CommonSelect options={[
                                              { label: 'Mr.', value: 'Mr.' },
                                              { label: 'Miss.', value: 'Miss.' },
                                            ]} />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                          <label>
                                            First Name <span className="login-danger">*</span>
                                          </label>
                                          <Form.Item name={'first_name'} rules={[{ required: true, message: 'This field is required' }]}>
                                            <input
                                              className="form-control"
                                              type="text"
                                              placeholder=""
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                          <label>
                                            Last Name <span className="login-danger">*</span>
                                          </label>
                                          <Form.Item name={'last_name'} rules={[{ required: true, message: 'This field is required' }]}>
                                            <input
                                              className="form-control"
                                              type="text"
                                              placeholder=""
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                          <label>
                                            Age <span className="login-danger">*</span>
                                          </label>
                                          <Form.Item name={'age'} rules={[{ required: true, message: 'This field is required' }]}>
                                            <input
                                              className="form-control"
                                              type="text"
                                              placeholder=""
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                          <label>
                                            Gender<span className="login-danger">*</span>
                                          </label>
                                          <Form.Item name={'gender'} rules={[{ required: true, message: 'This field is required' }]}>
                                            <CommonSelect
                                              options={[
                                                { label: 'Male', value: 'Male' },
                                                { label: 'Female', value: 'Female' },
                                                { label: 'Baby', value: 'Baby' }
                                              ]}
                                            />
                                            {/* <div className="form-check-inline">
                                    <label className="form-check-label">
                                      <input
                                        type="radio"
                                        name="gender"
                                        className="form-check-input"
                                      />
                                      Male
                                    </label>
                                  </div>
                                  <div className="form-check-inline">
                                    <label className="form-check-label">
                                      <input
                                        type="radio"
                                        name="gender"
                                        className="form-check-input"
                                      />
                                      Female
                                    </label>
                                  </div> */}
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms cal-icon">
                                          <label>
                                            Date Of Birth{" "}
                                            <span className="login-danger">*</span>
                                          </label>
                                          <Form.Item name={'date_of_birth'} getValueProps={val => {
                                            return dayjs(val);
                                          }} getValueFromEvent={dateObj => {
                                            setDate_of_birth_state(dateObj);
                                            return dayjs(dateObj).format('YYYY-MM-DD')
                                          }}>
                                            <DatePicker
                                              className="form-control datetimepicker"
                                              suffixIcon={null}
                                              value={date_of_birth_state}
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                          <label>
                                            Contact Number <span className="login-danger">*</span>
                                          </label>
                                          <Form.Item name={'contact_number'} rules={[{ required: true, message: 'This field is required' }, { max: 13, message: 'Maximum 10 digits allowed!' }, { min: 13, message: 'Minimum 10 digits allowed!' }]}>
                                            <PhoneInput
                                              international
                                              countryCallingCodeEditable={false}
                                              defaultCountry="PK"
                                              countries={['PK']}
                                              className="form-control disable-input-border"
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                          <label>
                                            Guardian
                                          </label>
                                          <Form.Item name={'guardian'}>
                                            <input
                                              className="form-control"
                                              type="text"
                                              placeholder=""
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                          <label>
                                            CNIC
                                          </label>
                                          <Form.Item name={'nic'}>
                                            <input
                                              className="form-control"
                                              type="text"
                                              placeholder=""
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                          <label>
                                            Passport
                                          </label>
                                          <Form.Item name={'passport_number'}>
                                            <input
                                              className="form-control"
                                              type="text"
                                              placeholder=""
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                          <label>
                                            Email
                                          </label>
                                          <Form.Item name={'email'}>
                                            <input
                                              className="form-control"
                                              type="email"
                                              placeholder=""
                                              autoComplete="no"
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                          <div>
                                            <label>
                                              Blood Group
                                            </label>
                                            <Form.Item name={'blood_group'}>
                                              <CommonSelect
                                                options={[
                                                  { label: 'A+', value: 'A+' },
                                                  { label: 'A-', value: 'A-' },
                                                  { label: 'B+', value: 'B+' },
                                                  { label: 'B-', value: 'B-' },
                                                  { label: 'AB+', value: 'AB+' },
                                                  { label: 'AB-', value: 'AB-' },
                                                  { label: 'O+', value: 'O+' },
                                                  { label: 'O-', value: 'O-' },
                                                ]}
                                              />
                                            </Form.Item>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                          <label>
                                            Referred by{" "}
                                          </label>
                                          <Form.Item name={'referred_by'}>
                                            <CommonSelect
                                              options={getDoctorsList?.map(item => {
                                                return {
                                                  label: item?.name,
                                                  value: item?.id,
                                                }
                                              })}
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-xl-6">
                                        <div className="form-group local-forms">
                                          <label>
                                            Panel <span className="login-danger">*</span>
                                          </label>
                                          <Form.Item name={'panel'} rules={[{ required: true, message: 'This field is required' }]}>
                                            <CommonSelect
                                              options={getPanelsList?.map(item => {
                                                return {
                                                  label: item?.name,
                                                  value: item?.id
                                                }
                                              })}
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="col-12">
                                        <Divider orientation="left" style={{ marginTop: 0 }}>Patient Tests</Divider>
                                      </div>
                                      <Form.List name="tests">
                                        {(fields, { add, remove }) => (
                                          <>
                                            {fields.map(({ key, name, ...restField }) => {
                                              return <>
                                                <div className="col-12 col-md-4 col-xl-4">
                                                  <div className="form-group local-forms">
                                                    <label>
                                                      Test
                                                    </label>
                                                    <Form.Item
                                                      {...restField}
                                                      name={[name, 'id']}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: 'This field is required',
                                                        },
                                                      ]}
                                                    >
                                                      <CommonSelect
                                                        options={getTestsList?.filter(a => !form.getFieldValue('tests')?.map(b => b?.id?.value)?.includes(a?.id))?.map(z => {
                                                          return {
                                                            label: z?.name,
                                                            value: z?.id,
                                                            price: z?.price,
                                                            sample_required: z?.sample_required,
                                                          }
                                                        })}
                                                      />
                                                    </Form.Item>
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-xl-4">
                                                  <div className="form-group local-forms">
                                                    <label>
                                                      Sample required
                                                    </label>
                                                    <Form.Item {...restField} name={[name, 'sample_required']}>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        // value={sampleRequired}
                                                        readOnly
                                                      />
                                                    </Form.Item>
                                                  </div>
                                                </div>

                                                <div className="col-5 col-md-2 col-xl-2">
                                                  <div className="form-group local-forms">
                                                    <label>
                                                      price
                                                    </label>
                                                    <Form.Item {...restField} name={[name, 'price']}>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        readOnly
                                                      />
                                                    </Form.Item>
                                                  </div>
                                                </div>
                                                <div className="col-5 col-md-1 col-xl-1">
                                                  <div className="form-group local-forms">
                                                    <Form.Item
                                                      {...restField}
                                                      name={[name, 'test_status']}
                                                      valuePropName="checked"
                                                    >
                                                      <label className="custom_check" style={{ marginTop: '22px' }}>
                                                        <input type="checkbox" />
                                                        <span className="checkmark" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Urgent
                                                      </label>
                                                    </Form.Item>
                                                  </div>
                                                </div>
                                                <div className="col-1 col-md-1 col-xl-1">
                                                  {key !== 0 && <>
                                                    {testRowLoading === key ? <>
                                                      <LoadingOutlined style={{ fontSize: 16, marginTop: '14px', marginLeft: '15px' }} spin />
                                                    </> : <>
                                                      {isOldRecord ? <>
                                                        <MinusCircleOutlined onClick={() => remove(name)} style={{ marginTop: '14px', marginLeft: '15px' }} />
                                                      </> : <>
                                                        {(fields?.length > 1 && form.getFieldValue('tests')[key]?.id && form.getFieldValue('tests')[key]?.patient_test_id) ? <MinusCircleOutlined onClick={() => handleDeleteTest(key)} style={{ marginTop: '14px', marginLeft: '15px' }} /> : <CheckOutlined style={{ margin: '10px 0 0 14px', cursor: 'pointer', color: 'green' }} onClick={() => handleAddNewTest(key)} />}
                                                      </>}
                                                    </>}
                                                  </>}
                                                </div>
                                              </>
                                            })}
                                            {form.getFieldValue('tests')?.length === getTestsList?.length ? '' : <Form.Item>
                                              <Button type="dashed" onClick={() => {
                                                if (getPatientRecord?.test_detail?.patient_test?.length < form.getFieldValue('tests')?.length) {
                                                  notification.warning({ message: 'Please Save the previous test first!' })
                                                } else {
                                                  add();
                                                }
                                              }} block icon={<PlusOutlined />}>
                                                Add test
                                              </Button>
                                            </Form.Item>}
                                          </>
                                        )}
                                      </Form.List>
                                      <div className="col-12 col-md-6 col-xl-6 offset-md-6 offset-xl-6">
                                        <div className="form-group local-forms">
                                          <label>
                                            Total amount
                                          </label>
                                          <Form.Item name={'total_amount'}>
                                            <input
                                              className="form-control"
                                              type="text"
                                              placeholder=""
                                              readOnly
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-xl-6 offset-md-6 offset-xl-6">
                                        <div className="form-group local-forms">
                                          <label>
                                            Discount amount
                                          </label>
                                          <div style={{ position: 'relative' }}>
                                            <Form.Item name={'discount_amount'}>
                                              <input type="text" className="form-control" placeholder="" />
                                            </Form.Item>

                                            <div style={{ position: 'absolute', right: 0, top: 0 }}>
                                              <Form.Item name={'discount_amount_type'}>
                                                <CommonSelect
                                                  options={[
                                                    { label: 'Percentage', value: 'percent' },
                                                    { label: 'Rupees', value: 'amount' }
                                                  ]}
                                                />
                                              </Form.Item>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-xl-6 offset-md-6 offset-xl-6">
                                        <div className="form-group local-forms">
                                          <label>
                                            Payable amount
                                          </label>
                                          <Form.Item name={'payable_amount'}>
                                            <input
                                              className="form-control"
                                              type="text"
                                              placeholder=""
                                              readOnly
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-xl-6 offset-md-6 offset-xl-6">
                                        <div className="form-group local-forms">
                                          <label>
                                            Due amount
                                          </label>
                                          <Form.Item name={'due_amount'}>
                                            <input
                                              className="form-control"
                                              type="text"
                                              placeholder=""
                                              readOnly
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-xl-6 offset-md-6 offset-xl-6">
                                        <div className="form-group local-forms">
                                          <label>
                                            Paid amount
                                          </label>
                                          <Form.Item name={'paid_amount'}>
                                            <input
                                              className="form-control"
                                              type="text"
                                              placeholder=""
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-12">
                                    <div className="doctor-submit text-end">
                                      <SubmitButton classnames={'btn btn-primary submit-form me-2'} loading={updatingRecord} buttonText={'Save'} />
                                      <button
                                        className="btn btn-primary cancel-form"
                                        onClick={e => {
                                          e?.preventDefault();
                                          navigate(`/patientslist`)
                                        }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Form>
                            </div>
                          </>}
                        </div>
                      </div>
                      {activeTab === 1 && <div className="card">
                        <div className="card-header">
                          <h4 className="card-title ">Tests detail</h4>
                        </div>
                        <div className="card-body p-0 table-dash">
                          <div className="table-responsive">
                            <table className="table mb-0 border-0 datatable custom-table patient-profile-table">
                              <thead>
                                <tr>
                                  <th>Test name</th>
                                  <th>Department</th>
                                  <th>Charges</th>
                                  <th>Result</th>
                                  <th>Created at</th>
                                  <th>Updated at</th>
                                  <th />
                                </tr>
                              </thead>
                              <tbody>
                                {getPatientRecord?.patient_tests?.length ? <>
                                  {getPatientRecord?.patient_tests?.map(item => {
                                    return <tr>
                                      <td><b>{item?.tests?.name || '-'}</b></td>
                                      <td>{item?.tests?.department?.name || '-'}</td>
                                      <td>{item?.tests?.price}</td>
                                      <td>{item?.tests?.result?.current_result || '-'}</td>
                                      <td>{item?.tests?.created_at ? moment(item?.tests?.created_at)?.format('DD/MM/YYYY') : ''}</td>
                                      <td>{item?.tests?.updated_at ? moment(item?.tests?.updated_at)?.format('DD/MM/YYYY') : ''}</td>
                                    </tr>
                                  })}
                                </> : <></>}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PageLayout>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button className="btn btn-danger" data-bs-dismiss="modal" onClick={async () => {
                      await deletePatient(getPatientRecord?.patient_id);
                      navigate(`${HOME_PAGE_URL}`)
                    }}>
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    </Spin>
  );
});

export default PatientDetails;
