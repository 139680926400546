// import makeInspectable from 'mobx-devtools-mst';
// import {initSubStore, SubStore} from './sub-store/subStore';
import {flow, types} from 'mobx-state-tree';
import {useMemo} from 'react';
import api from '../api';
import { toJS } from 'mobx';
import { ConfiguratorStore, initConfiguratorStore } from './configurator-store';
import { initPatientsStore, PatientsStore } from './patients-store';
import { initTestsStore, TestsStore } from './tests-store';
import { DoctorsStore, initDoctorsStore } from './doctors-store';
import { initPanelsStore, PanelsStore } from './panels-store';
import { DepartmentsStore, initDepartmentsStore } from './departments-store';

const RootStore = types.model({
  configuratorStore: ConfiguratorStore,
  patientsStore: PatientsStore,
  testsStore: TestsStore,
  doctorsStore: DoctorsStore,
  panelsStore: PanelsStore,
  departmentsStore: DepartmentsStore,
  user: types.maybeNull(types.model({
    company_logo: types.maybeNull(types.string),
    company_name: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    first_name: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
    last_name: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    certifications: types.maybeNull(types.array(types.model({
      logo: types.maybeNull(types.string),
      name: types.maybeNull(types.string),
      registration_no: types.maybeNull(types.string),
    }))),
    footer: types.maybeNull(types.string),
    full_name: types.maybeNull(types.string),
    header: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    contact_number: types.maybeNull(types.string),
    official_email: types.maybeNull(types.string),
    profile_completed: types.maybeNull(types.boolean),
  })),
  loggingIn: types.boolean,
  signingIn: types.boolean,
  devMode: types.boolean,
}).views(self => ({
  get getUserData() {
    return toJS(self.user);
  },
})).actions(self => {
  const signup = flow(function* (payload) {
    self.signingIn = true;
    try {
      const response = yield api.post(`/users/signup/`, payload);
      localStorage.setItem('access_token', response?.data?.data?.access_token || '');
      fetchUser();
      self.signingIn = false;
      return response?.data;
    } catch (e) {
      self.signingIn = false;
      return Promise.reject(e);
    }
  });
  const login = flow(function* (payload) {
    self.loggingIn = true;
    try {
      const response = yield api.post(`/users/login/`, payload);
      self.user = response?.data?.data;
      localStorage.setItem('access_token', response?.data?.data?.access_token || '');
      self.loggingIn = false;
      return response?.data;
    } catch (e) {
      self.loggingIn = false;
      return Promise.reject(e);
    }
  });
  const fetchUser = flow(function* () {
    try {
      const response = yield api.get(`users/user_detail/`);
      self.user = response?.data?.data;
      return response?.data;
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const setDevMode = (val) => {
    self.devMode = val;
  };

  const updateProfile = flow(function* (payload) {
    self.updatingProfile = true;
    try {
      const response = yield api.put(`/users/update_profile/`, payload);
      fetchUser();
      self.updatingProfile = false;
      return response?.data;
    } catch (e) {
      self.updatingProfile = false;
      return Promise.reject(e);
    }
  });
  
  return {
    login,
    signup,
    setDevMode,
    fetchUser,
    updateProfile,
  };
});


let store;

export function resetStore() {
    store.reset();
  }

export function initializeStore(snapshot = null) {
  const _store =
    store ??
    RootStore.create({
      configuratorStore: initConfiguratorStore(),
      patientsStore: initPatientsStore(),
      testsStore: initTestsStore(),
      doctorsStore: initDoctorsStore(),
      panelsStore: initPanelsStore(),
      departmentsStore: initDepartmentsStore(),
      user: {
        username: '',
        full_name: '',
      },
      loggingIn: false,
      signingIn: false,
      devMode: false,
    });

  if (typeof window === 'undefined') {
    return _store;
  }
  if (!store) {
    store = _store;
  }
//   makeInspectable(store);
  return store;
}

export function useStore(initialState) {
  return useMemo(() => initializeStore(initialState), [initialState]);
}