import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Button, DatePicker, Divider, Form, Input, notification, Radio, Space, Upload } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { useStore } from "../../store/root-store";
import moment from "moment/moment";
import { LoadingOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import './styles.css';
import { DocumentHead } from "../../utils/documentHead";
import { PageLayout } from "../../common-components/pageLayout";

const AddTestProfile = observer(() => {
  const { testsStore: { creatingRecord, createTestRecord, fetchTestsListIndividuals, getTestsListOptions } } = useStore('');
  const navigate = useNavigate();
  useEffect(() => {
    fetchTestsListIndividuals();
  }, []);
  // const [selectedOption, setSelectedOption] = useState(null);
  // const [option, setOption] = useState([
  //   { value: 1, label: "Select City" },
  //   { value: 2, label: "Alaska" },
  //   { value: 3, label: "California" },
  // ]);
  // const [options, setOptions] = useState([
  //   { value: 1, label: "Select Country" },
  //   { value: 2, label: "Usa" },
  //   { value: 3, label: "Uk" },
  //   { value: 4, label: "Italy" },
  // ]);
  // const onChange = (date, dateString) => {
  //   // console.log(date, dateString);
  // };
  const [department, setDepartment] = useState([
    { value: 'Panel A', label: "Panel A" },
    { value: 'Panel B', label: "Panel B" },
    { value: 'Panel C', label: "Panel C" },
  ]);
  const [imageUrl, setImageUrl] = useState();

  const [form] = Form.useForm();

  const getValueFromEvent = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        form.setFieldsValue({
          profile_image: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
    return e && e.fileList;
  };

  const getBase64 = async (img) => {
    const reader = new FileReader();
    await reader.addEventListener('load', () => {
      form.setFieldsValue({
        profile_image: reader.result,
      });
      setImageUrl(reader.result);
    });
    reader.readAsDataURL(img);
    return reader.result;
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      {<PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const animatedComponents = makeAnimated();
  return (
    <div>
      <DocumentHead title={'Tests'} />
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-test-profile"
      />
      <>
        <PageLayout>
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Test </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Test Profile</li>
                  </ul>
                  {/* <button onClick={() => generateReceipt()}>download</button> */}
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <Form form={form} initialValues={{
                      profiling_tests: [{}]
                    }} onValuesChange={(val, allValues) => {
                      const priceSum = allValues?.profiling_tests?.filter(z => z?.id)?.reduce((a, b) => a + Number(b?.id?.price), 0);
                      const profilingTests = allValues?.profiling_tests?.map(item => {
                        return {
                          ...item,
                          sample_required: item?.id?.sample_required || '',
                          price: item?.id?.price,
                        }
                      });
                      if (val?.total_amount) {
                        form.setFieldsValue({
                          profiling_tests: profilingTests
                        });
                      } else {
                        form.setFieldsValue({
                          total_amount: priceSum,
                          profiling_tests: profilingTests
                        });
                      }

                    }} onFinish={async (values) => {
                      const dataObj = {
                        "test_type" : 'Profiling',
                        "name": values?.name,
                        "sample_required": values?.sample_required?.value,
                        "profiling_tests": values?.profiling_tests?.map(z => z?.id?.value) || [],
                        "price": values?.total_amount
                      };
                      const res = await createTestRecord(dataObj);
                      if (res?.status_code == 200) {
                        notification.success({ message: res?.message });
                        form.resetFields();
                        navigate('/tests-list')
                      } else {
                        notification.error({ message: 'Error occured!', description: JSON.stringify(res?.message) })
                      }
                    }}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Test Profile Details</h4>
                          </div>
                        </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <Form.Item name={'name'} rules={[{ required: true, message: 'This field is required' }]}>
                                  <div>
                                    <label>
                                      Name <span className="login-danger">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder=""
                                    />
                                  </div>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Sample required
                            </label>
                            <Form.Item
                              name={'sample_required'}>
                              <Select
                                options={[
                                  { label: 'Blood', value: 'Blood' },
                                  { label: 'Urine', value: 'Urine' }
                                ]}
                                components={{
                                  IndicatorSeparator: () => null
                                }}
                                menuPortalTarget={document.body}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                    boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                    '&:hover': {
                                      borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                    },
                                    borderRadius: '10px',
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                    transition: '250ms',
                                    width: '35px',
                                    height: '35px',
                                  }),
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                            {/* <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Tests <span className="login-danger">*</span>
                                </label>
                                <Form.Item name={'profiling_tests'} rules={[{ required: true, message: 'This field is required' }]}>
                                  <Select
                                    options={getTestsListOptions?.map(z => {
                                      return {
                                        label: z?.name,
                                        value: z?.id
                                      }
                                    })}
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    menuPortalTarget={document.body}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                        boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                        '&:hover': {
                                          borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                        },
                                        borderRadius: '10px',
                                        fontSize: "14px",
                                        minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                        transition: '250ms',
                                        width: '35px',
                                        height: '35px',
                                      }),
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div> */}
                            <div className="col-12">
                              <Divider orientation="left" style={{ marginTop: 0 }}>Add Tests</Divider>
                            </div>
                            <Form.List name="profiling_tests">
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ key, name, ...restField }) => {
                                    return <>
                                    <div className="col-12 col-md-4 col-xl-4">
                                      <div className="form-group local-forms">
                                        <label>
                                          Test
                                        </label>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'id']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'This field is required',
                                            },
                                          ]}
                                        >
                                          <Select
                                            options={getTestsListOptions?.map(z => {
                                              return {
                                                label: z?.name,
                                                value: z?.id,
                                                price: z?.price,
                                                sample_required: z?.sample_required,
                                              }
                                            })}
                                            components={{
                                              IndicatorSeparator: () => null
                                            }}
                                            menuPortalTarget={document.body}
                                            styles={{
                                              control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                                boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                                '&:hover': {
                                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                                },
                                                borderRadius: '10px',
                                                fontSize: "14px",
                                                minHeight: "45px",
                                              }),
                                              dropdownIndicator: (base, state) => ({
                                                ...base,
                                                transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                                transition: '250ms',
                                                width: '35px',
                                                height: '35px',
                                              }),
                                            }}
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-xl-4">
                                      <div className="form-group local-forms">
                                        <label>
                                          Sample required
                                        </label>
                                          <Form.Item {...restField} name={[name, 'sample_required']}>
                                            <input
                                            className="form-control"
                                            type="text"
                                            placeholder=""
                                            // value={sampleRequired}
                                            readOnly
                                          />
                                          </Form.Item>
                                      </div>
                                    </div>
                                
                                    <div className="col-10 col-md-3 col-xl-3">
                                      <div className="form-group local-forms">
                                        <label>
                                          price
                                        </label>
                                        <Form.Item {...restField} name={[name, 'price']}>
                                        <input
                                          className="form-control"
                                          type="text"
                                          placeholder=""
                                          readOnly
                                        />
                                        </Form.Item>
                                      </div>
                                    </div>
                                    <div className="col-2 col-md-1 col-xl-1">
                                      {key !== 0 && <MinusCircleOutlined onClick={() => remove(name)} style={{ marginTop: '14px', marginLeft: '10px' }} />}
                                    </div>
                                  </>
                                  })}
                                  <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                      Add Test
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                            <div className="col-12 col-md-6 col-xl-6 offset-md-6 offset-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Total amount
                                </label>
                                <Form.Item name={'total_amount'}>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    // readOnly
                                  />
                                </Form.Item>
                              </div>
                            </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className={`btn btn-primary submit-form me-2${creatingRecord ? ' disabled' : ''}`}
                            >
                              {creatingRecord ? <><LoadingOutlined style={{fontSize: 16}} spin />&nbsp;</> : <></>}Submit
                            </button>
                            <button
                              className="btn btn-primary cancel-form"
                              onClick={e => {
                                e?.preventDefault();
                                navigate(`/tests-list`)
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </PageLayout>
      </>
    </div>
  );
});

export default AddTestProfile;
