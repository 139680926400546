import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { favicon, imagesend } from "../imagepath";
import { Button, DatePicker, Divider, Form, notification, Radio } from "antd";
import FeatherIcon from "feather-icons-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store/root-store";
import moment from "moment";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { DocumentHead } from "../../utils/documentHead";
import { PageLayout } from "../../common-components/pageLayout";

const EditTest = observer(() => {
  const {testsStore: {testRecordLoading, getTestRecord, fetchTestRecord, updateTestRecord, updatingRecord, fetchDepartmentsList, getDepartmentsList}} = useStore('');
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {id} = useParams();
  useEffect(() => {
    fetchDepartmentsList();
  }, []);
  const fillRecord = async () => {
    const res = await fetchTestRecord(id);
    if (res?.data) {
      const dataObj = (res?.data?.source === 'platform' && res?.data?.updated_test?.name) ? res?.data?.updated_test : res?.data;

      form.setFieldsValue({
        name: dataObj?.name,
        unit: {label: dataObj?.unit, value: dataObj?.unit},
        report_time: dataObj?.report_time,
        price: dataObj?.price,
        normal_range: dataObj?.normal_range,
        sample_required: {label: dataObj?.sample_required, value: dataObj?.sample_required},
        department: {label: res?.data?.department?.name, value: res?.data?.department?.id}
      })
    }
  }
  useEffect(() => {
    if (id) {
      fillRecord();
    }
}, [id]);

  return (
    <div>
      <DocumentHead title={'Tests'} />
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="edit-test"
      />
      <>
        <PageLayout>
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="# ">Tests </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Edit Test</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                  <Form form={form} onFinish={async (values) => {
                      const dataObj = {
                        "test_type" : 'Individual',
                        "name": values?.name,
                        "price": values?.price,
                        "report_time": values?.report_time,
                        "normal_range": values?.normal_range,
                        "unit": values?.unit?.value,
                        "sample_required": values?.sample_required?.value,
                        "department": values?.department?.value,
                      };
                      const res = await updateTestRecord(id, dataObj);
                      if (res?.status_code == 200) {
                        notification.success({ message: res?.message });
                        form.resetFields();
                        navigate('/tests-list')
                      } else {
                        notification.error({ message: 'Error occured!', description: JSON.stringify(res?.message) })
                      }
                    }}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Test Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Department <span className="login-danger">*</span>
                            </label>
                            <Form.Item name={'department'} rules={[{ required: true, message: 'This field is required' }]}>
                              <Select
                                options={getDepartmentsList?.map(item => {
                                  return {
                                    label: item?.name,
                                    value: item?.id,
                                  }
                                })}
                                components={{
                                  IndicatorSeparator: () => null
                                }}
                                menuPortalTarget={document.body}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                    boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                    '&:hover': {
                                      borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                    },
                                    borderRadius: '10px',
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                    transition: '250ms',
                                    width: '35px',
                                    height: '35px',
                                  }),
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Name <span className="login-danger">*</span>
                                </label>
                                <Form.Item name={'name'} rules={[{ required: true, message: 'This field is required' }]}>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Price <span className="login-danger">*</span>
                                </label>
                                <Form.Item name={'price'} rules={[{ required: true, message: 'This field is required' }]}>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Report Time <span className="login-danger">*</span>
                                </label>
                                <div className="input-group">
                                  <Form.Item style={{width: 'calc(100% - 68px)', marginBottom: 0}} name={'report_time'} rules={[{ required: true, message: 'This field is required' }]}>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder=""
                                      style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                    />
                                  </Form.Item>
                                  <span className="input-group-text">Hours</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Normal Range{" "}
                                </label>
                                <Form.Item name={'normal_range'}>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Unit <span className="login-danger">*</span>
                                </label>
                                <Form.Item name={'unit'} rules={[{ required: true, message: 'This field is required' }]}>
                                  <Select
                                    options={[
                                      {label: 'ml', value: 'ml'},
                                      {label: 'mm', value: 'mm'}
                                    ]}
                                    components={{
                                      IndicatorSeparator: () => null
                                    }}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                        boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                        '&:hover': {
                                          borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                        },
                                        borderRadius: '10px',
                                        fontSize: "14px",
                                        minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                        transition: '250ms',
                                        width: '35px',
                                        height: '35px',
                                      }),
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Sample required
                            </label>
                            <Form.Item
                              name={'sample_required'}>
                              <Select
                                options={[
                                  { label: 'Blood', value: 'Blood' },
                                  { label: 'Urine', value: 'Urine' }
                                ]}
                                components={{
                                  IndicatorSeparator: () => null
                                }}
                                menuPortalTarget={document.body}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                    boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                    '&:hover': {
                                      borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                    },
                                    borderRadius: '10px',
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                    transition: '250ms',
                                    width: '35px',
                                    height: '35px',
                                  }),
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className={`btn btn-primary submit-form me-2${updatingRecord ? ' disabled' : ''}`}
                            >
                              {updatingRecord ? <><LoadingOutlined style={{fontSize: 16}} spin />&nbsp;</> : <></>}Submit
                            </button>
                            <button
                              className="btn btn-primary cancel-form"
                              onClick={e => {
                                e?.preventDefault();
                                navigate(`/tests-list`)
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </PageLayout>
        <div
          id="delete_test"
          className="modal fade delete-modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={imagesend} alt="" width={50} height={46} />
                <h3>Are you sure want to delete this ?</h3>
                <div className="m-t-20">
                  {" "}
                  <Link
                    to="#"
                    className="btn btn-white"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </Link>
                  <button type="submit" className="btn btn-danger">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidebar-overlay" data-reff="" />
        {/* Datepicker Core JS */}
      </>
    </div>
  );
});

export default EditTest;
