import React,{ useEffect, useState }  from 'react';
import { Table } from "antd";
import Header from '../Header';
import Sidebar from '../Sidebar';
import {onShowSizeChange,itemRender}from  '../Pagination'
import { imagesend, plusicon, searchnormal } from '../imagepath';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store/root-store';
import moment from 'moment';
import { Delete, DeleteForever, Edit } from '@mui/icons-material';
import { DocumentHead } from '../../utils/documentHead';
import { PageLayout } from '../../common-components/pageLayout';
const TestsList = observer(() => {
  const {testsStore: {resetPagination, getTestsList, testsListLoading, totalCount, handlePagination, fetchTestsList, deleteTest}} = useStore('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedDeleteId, setSelectedDeleteId] = useState(null);
  useEffect(() => {
    fetchTestsList();
}, []);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
  };

  const columns = [
      {
          title: "Name",
          dataIndex: "name",
          sorter: (a, b) => a.name?.length - b.name?.length,
          render: (text, record) => {
            const dataObj = (record?.source === 'platform' && record?.updated_test?.name) ? record?.updated_test : record;
            return <>
                <h2 className="profile-image">
                    <Link to="#">{dataObj?.name}</Link>
                </h2>
            </>
          }
      },
      // {
      //     title:"Description",
      //     dataIndex: "description",
      //     sorter: (a, b) => a.description?.length - b.description?.length,
      // },
      {
          title:"Normal Range",
          dataIndex: "normal_range",
          sorter: (a, b) => a.normal_range?.length - b.normal_range?.length,
          render: (text, record) => {
            const dataObj = (record?.source === 'platform' && record?.updated_test?.name) ? record?.updated_test : record;
            return <>{dataObj?.normal_range}</>
          }
      },
      {
        title:"Unit",
        dataIndex: "unit",
        sorter: (a, b) => a.unit?.length - b.unit?.length,
        render: (text, record) => {
          const dataObj = (record?.source === 'platform' && record?.updated_test?.name) ? record?.updated_test : record;
          return <>{dataObj?.unit}</>
        }
      },
      {
          title:"Price",
          dataIndex: "price",
          sorter: (a, b) => a.price - b.price,
          render: (text, record) => {
            const dataObj = (record?.source === 'platform' && record?.updated_test?.name) ? record?.updated_test : record;
            return <>{dataObj?.price}</>
          }
        },
      {
        title:"Report Time",
        dataIndex: "report_time",
        sorter: (a, b) => a.report_time - b.report_time,
        render: (text, record) => {
          const dataObj = (record?.source === 'platform' && record?.updated_test?.name) ? record?.updated_test : record;
          return <>{dataObj?.report_time} hours</>
        }
    },
    {
      title:"Type",
      dataIndex: "test_type",
      sorter: (a, b) => a.test_type?.length - b.test_type?.length,
    },
  //   {
  //     title:"Created by",
  //     dataIndex: "created_by",
  //     sorter: (a, b) => a.created_by?.length - b.created_by?.length,
  // },
      {
          title:"Created at",
          dataIndex: "created_at",
          sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
          render: (text, record) => {
            return <>{text ? moment(text)?.format('DD MMM YYYY') : ''}</>
          }
      },
      {
        title:"Updated at",
        dataIndex: "updated_at",
        sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
        render: (text, record) => {
          return <>{text ? moment(text)?.format('DD MMM YYYY') : ''}</>
        }
    },
      {
        title: "",
        dataIndex: "FIELD8",
        render: (text, record) => (
          <>
            <div className="text-center">
              <Link className="me-2" to={record?.test_type == 'Profiling' ? `/edit-test-profile/${record?.id}` : `/edit-test/${record?.id}`}>
                <Edit style={{color: '#2e37a4'}}/>
              </Link>
              <span style={{cursor: 'pointer'}} className="me-2" onClick={() => setSelectedDeleteId(record?.id)} data-bs-toggle="modal" data-bs-target="#delete_test">
                <DeleteForever style={{color: '#ff0000'}}/>
              </span>
              {/* <div className="dropdown dropdown-action">
                <Link
                  to="#"
                  className="action-icon dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v" />
                </Link>
                <div className="dropdown-menu dropdown-menu-end">
                  <Link className="dropdown-item" to={record?.test_type == 'Profiling' ? `/edit-test-profile/${record?.id}` : `/edit-test/${record?.id}`}>
                    <i className="far fa-edit me-2" />
                    Edit
                  </Link>
                  <div style={{cursor: 'pointer'}} className="dropdown-item" onClick={() => setSelectedDeleteId(record?.id)} data-bs-toggle="modal" data-bs-target="#delete_test">
                    <i className="fa fa-trash-alt m-r-5"></i> Delete</div>
                </div>
              </div> */}
            </div>
          </>
        ),
      },

  ]
  const [timer, setTimer] = useState(null);
  const onSearchHandler = e => {
    const value = e.target.value || '';
    setQuery(value);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        resetPagination();
        fetchTestsList(value);
      }, 600),
    );
  };

return (
  <>
  <DocumentHead title={'Tests'} />
  <Header />
  <Sidebar id='menu-item2' id1='menu-items2' activeClassName='test-list' />
<PageLayout>
    {/* Page Header */}
    <div className="page-header">
      <div className="row">
        <div className="col-sm-12">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="#">Tests </Link>
            </li>
            <li className="breadcrumb-item">
              <i className="feather-chevron-right" />
            </li>
            <li className="breadcrumb-item active">Tests List</li>
          </ul>
        </div>
      </div>
    </div>
    {/* /Page Header */}
    <div className="row">
      <div className="col-sm-12">
        <div className="card card-table show-entire">
          <div className="card-body">
            {/* Table Header */}
            <div className="page-table-header mb-2">
              <div className="row align-items-center">
                <div className="col">
                  <div className="doctor-table-blk" style={{justifyContent: 'space-between'}}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <h3>Tests List</h3>
                      <div className="top-nav-search table-search-blk">
                        <div className='search-form-style'>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search here"
                            value={query}
                            onChange={onSearchHandler}
                          />
                          <Link className="btn">
                            <img
                              src={searchnormal}
                              alt="#"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="doctor-search-blk">
                      <div className="add-group">
                        <Link
                         to="/add-tests"
                          className="btn btn-primary add-pluss ms-2"
                          style={{width: 'fit-content'}}
                        >
                          <img src={plusicon} alt="#" />&nbsp;<span style={{fontWeight: '600'}}>Test</span>
                        </Link>
                        <Link
                         to="/add-test-profile"
                          className="btn btn-primary add-pluss ms-2"
                          style={{width: 'fit-content'}}
                        >
                          <img src={plusicon} alt="#" />&nbsp;<span style={{fontWeight: '600'}}>Test Profile</span>
                        </Link>
                        {/* <Link
                          to="#"
                          className="btn btn-primary doctor-refresh ms-2"
                        >
                          <img src={refreshicon}alt="#" />
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-auto text-end float-end ms-auto download-grp">
                  <Link to="#" className=" me-2">
                    <img src={pdficon} alt="#" />
                  </Link>
                  <Link to="#" className=" me-2">
                  </Link>
                  <Link to="#" className=" me-2">
                    <img src={pdficon3} alt="#" />
                  </Link>
                  <Link to="#">
                    <img src={pdficon4} alt="#" />
                  </Link>
                </div> */}
              </div>
            </div>
            {/* /Table Header */}
            <div className="table-responsive doctor-list">
              <Table
              style={{whiteSpace: 'nowrap'}}
                pagination={{
                  total: totalCount,
                  showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  // showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                  onChange: handlePagination,
                }}
                columns={columns}
                dataSource={getTestsList}
                loading={testsListLoading}
                // rowSelection={rowSelection}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    </PageLayout>
<div id="delete_test" className="modal fade delete-modal" role="dialog">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-body text-center">
        <img src={imagesend}alt="#" width={50} height={46} />
        <h3>Are you sure want to delete this ?</h3>
        <div className="m-t-20">
          {" "}
          <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
            Close
          </Link>
          <button className="btn btn-danger" data-bs-dismiss="modal" onClick={async () => {
            await deleteTest(selectedDeleteId);
            fetchTestsList();
          }}>
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</>

)
})

export default TestsList;
