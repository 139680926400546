import React,{ useEffect, useState }  from 'react';
import { Table } from "antd";
import Header from '../Header';
import Sidebar from '../Sidebar';
import {onShowSizeChange,itemRender}from  '../Pagination'
import { blogimg10, imagesend, pdficon, pdficon3, pdficon4, plusicon, refreshicon, searchnormal, blogimg12,
     blogimg2, blogimg4, blogimg6, blogimg8} from '../imagepath';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store/root-store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCross } from '@fortawesome/free-solid-svg-icons';
import api from '../../api';
import moment from 'moment/moment';
import { PageLayout } from '../../common-components/pageLayout';

const ConfiguratorList = observer(() => {
    const {configuratorStore: {getConfiguratorData, configuratorListLoading, fetchConfiguratorData}} = useStore('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    useEffect(() => {
        fetchConfiguratorData();
    }, []);
    const onSelectChange = (newSelectedRowKeys) => {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const datasource = [
        {
          id:"1",
          Img:blogimg2,
          Name: "Andrea Lalema",
          Department: "Otolaryngology",
          Specialization: "Infertility",
          Degree: "MBBS, MS",
          Mobile: "+1 23 456890",
          Email: "example@email.com",
          JoiningDate: "01.10.2022",
          FIELD9: ""
        },
        {
          id:"2",
          Img:blogimg4,
          Name: "Dr.Smith Bruklin",
          Department: "Urology",
          Specialization: "Prostate",
          Degree: "MBBS, MS",
          Mobile: "+1 23 456890",
          Email: "example@email.com",
          JoiningDate: "01.10.2022",
          FIELD9: ""
        },
        {
          id:"3",
          Img:blogimg6,
          Name: "Dr.William Stephin",
          Department: "Radiology",
          Specialization: "Cancer",
          Degree: "MBBS, MS",
          Mobile: "+1 23 456890",
          Email: "example@email.com",
          JoiningDate: "01.10.2022",
          FIELD9: ""
        },
        {
          id:"4",
          Img:blogimg12,
          Name: "Bernardo James",
          Department: "Dentist",
          Specialization: "Prostate",
          Degree: "MBBS, MS",
          Mobile: "+1 23 456890",
          Email: "example@email.com",
          JoiningDate: "01.10.2022",
          FIELD9: ""
        },
        {
          id:"5",
          Img:blogimg10,
          Name: "Cristina Groves",
          Department: "Gynocolgy",
          Specialization: "Prostate",
          Degree: "MBBS, MS",
          Mobile: "+1 23 456890",
          Email: "example@email.com",
          JoiningDate: "01.10.2022",
          FIELD9: ""
        },
        {
          id:"6",
          Img:blogimg8,
          Name: "Mark Hay Smith",
          Department: "Gynocolgy",
          Specialization: "Prostate",
          Degree: "MBBS, MS",
          Mobile: "+1 23 456890",
          Email: "example@email.com",
          JoiningDate: "01.10.2022",
          FIELD9: ""
        },
        {
          id:"7",
          Img:blogimg2,
          Name: "Andrea Lalema",
          Department: "Otolaryngology",
          Specialization: "Infertility",
          Degree: "MBBS, MS",
          Mobile: "+1 23 456890",
          Email: "example@email.com",
          JoiningDate: "01.10.2022",
          FIELD9: ""
        },
        {
          id:"8",
          Img:blogimg4,
          Name: "Dr.Smith Bruklin",
          Department: "Urology",
          Specialization: "Prostate",
          Degree: "MBBS, MS",
          Mobile: "+1 23 456890",
          Email: "example@email.com",
          JoiningDate: "01.10.2022",
          FIELD9: ""
        }
    ]

    const handleDelete = async (id) => {
        try {
            await api.delete(`/labs/delete_configurator/${id}/`);
            fetchConfiguratorData();
        } catch (error) {
            
        }
    }
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: true,
            width: '25%',
        },
        {
            title:"Company",
            dataIndex: "company",
            align: 'center',
        },
        {
            title:"Active",
            dataIndex: "is_active",
            sorter: (a, b) => Number(a.is_active) > Number(b.is_active) ? -1 : 1,
            align: 'center',
            render: (text, record) => (
                <>
                    {record?.is_active ? <FontAwesomeIcon icon={faCheck} color='green' /> : <FontAwesomeIcon icon={faCross} color='red' />}
                </>
            )
        },
        {
            title:"Created at",
            dataIndex: "created_at",
            sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
            render: (text, record) => (
                <>
                    {record?.created_at ? moment(record.created_at)?.format('DD MMM YYYY HH:mm A') : '-'}
                </>
            )
        },
        {
            title:"Updated at",
            dataIndex: "updated_at",
            sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
            render: (text, record) => (
                <>
                    {record?.updated_at ? moment(record.updated_at)?.format('DD MMM YYYY HH:mm A') : '-'}
                </>
            )
        },
        // {
        //   title: "",
        //   dataIndex: "FIELD8",
        //   render: (text, record) => (
        //     <>
        //       <div className="text-end">
        //         <div className="dropdown dropdown-action">
        //           <Link
        //             to="#"
        //             className="action-icon dropdown-toggle"
        //             data-bs-toggle="dropdown"
        //             aria-expanded="false"
        //           >
        //             <i className="fas fa-ellipsis-v" />
        //           </Link>
        //           <div className="dropdown-menu dropdown-menu-end">
        //             <Link className="dropdown-item" to="/edit-patients">
        //               <i className="far fa-edit me-2" />
        //               Edit
        //             </Link>
        //             <Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target={`#delete_configurator_item_${record?.id}`}>
        //               <i className="fa fa-trash-alt m-r-5"></i> Delete</Link>
        //           </div>
        //         </div>
        //       </div>
        //       <div id={`delete_configurator_item_${record?.id}`} className="modal fade delete-modal" role="dialog">
        //             <div className="modal-dialog modal-dialog-centered">
        //             <div className="modal-content">
        //                 <div className="modal-body text-center">
        //                 <img src={imagesend}alt="#" width={50} height={46} />
        //                 <h3>Are you sure want to delete this ?</h3>
        //                 <div className="m-t-20">
        //                     {" "}
        //                     <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
        //                     Close
        //                     </Link>
        //                     <button data-bs-dismiss="modal" onClick={() => {
        //                         handleDelete(record?.id);
        //                     }} className="btn btn-danger">
        //                     Delete
        //                     </button>
        //                 </div>
        //                 </div>
        //             </div>
        //             </div>
        //         </div>
        //     </>
        //   ),
        // },

    ]


  return (
    <>
    <Header />
    <Sidebar id='menu-item2' id1='menu-items2' activeClassName='configurator-list' />
    <PageLayout>
      {/* Page Header */}
      <div className="page-header">
        <div className="row">
          <div className="col-sm-12">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="#">Configurator </Link>
              </li>
              <li className="breadcrumb-item">
                <i className="feather-chevron-right" />
              </li>
              <li className="breadcrumb-item active">Configurator List</li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Page Header */}
      <div className="row">
        <div className="col-sm-12">
          <div className="card card-table show-entire">
            <div className="card-body">
              {/* Table Header */}
              <div className="page-table-header mb-2">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="doctor-table-blk">
                      <h3>Configurator List</h3>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Table Header */}
              <div className="table-responsive doctor-list">
              <Table
                        pagination={{
                          total: getConfiguratorData.length,
                          showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          // showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={columns}
                        loading={configuratorListLoading}
                        dataSource={getConfiguratorData}
                        // rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                      />
              </div>
            </div>
          </div>
        </div>
      </div>
      </PageLayout>
  
</>

  )
})

export default ConfiguratorList;
